import './galleryCont.css'
import { useEffect, useState } from 'react';
import Headercomp from "../component/headerComp";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import ain1 from '../assets/Garden/ain1.JPG'
import ain2 from '../assets/Garden/ain2.JPG'
import ain3 from '../assets/Garden/ain3.JPG'
import ain4 from '../assets/Garden/ain4.JPG'
import ain5 from '../assets/Garden/ain5.JPG'

import arai1 from '../assets/Garden/arai1.JPG'
import arai2 from '../assets/Garden/arai2.JPG'
import arai3 from '../assets/Garden/arai3.JPG'
import arai4 from '../assets/Garden/arai4.JPG'
import arai5 from '../assets/Garden/arai5.JPG'

import balt1 from '../assets/Garden/balt1.JPG'
import balt2 from '../assets/Garden/balt2.JPG'
import balt3 from '../assets/Garden/balt3.JPG'
import balt4 from '../assets/Garden/balt4.JPG'
import balt5 from '../assets/Garden/balt5.JPG'

import berg1 from '../assets/Garden/berg1.JPG'
import berg2 from '../assets/Garden/berg2.JPG'
import berg3 from '../assets/Garden/berg3.JPG'
import berg4 from '../assets/Garden/berg4.JPG'
import berg5 from '../assets/Garden/berg5.JPG'

import bied1 from '../assets/Garden/bied1.JPG'
import bied2 from '../assets/Garden/bied2.JPG'
import bied3 from '../assets/Garden/bied3.JPG'
import bied4 from '../assets/Garden/bied4.JPG'
import bied5 from '../assets/Garden/bied5.JPG'

import brod1 from '../assets/Garden/brod1.JPG'
import brod2 from '../assets/Garden/brod2.JPG'
import brod3 from '../assets/Garden/brod3.JPG'
import brod4 from '../assets/Garden/brod4.JPG'
import brod5 from '../assets/Garden/brod5.JPG'

import iz1 from '../assets/Garden/iz1.jpg'
import iz2 from '../assets/Garden/iz2.jpg'
import iz3 from '../assets/Garden/iz3.jpg'
import iz4 from '../assets/Garden/iz4.jpg'
import iz5 from '../assets/Garden/iz5.JPG'

import lil1 from '../assets/Garden/lil1.JPG'
import lil2 from '../assets/Garden/lil2.JPG'
import lil3 from '../assets/Garden/lil3.JPG'
import lil4 from '../assets/Garden/lil4.JPG'
import lil5 from '../assets/Garden/lil5.JPG'

import sel1 from '../assets/Garden/sel1.jpg'
import sel2 from '../assets/Garden/sel2.jpg'
import sel3 from '../assets/Garden/sel3.jpg'
import sel4 from '../assets/Garden/sel4.jpg'
import sel5 from '../assets/Garden/sel5.jpg'

import sky1 from '../assets/Garden/sky1.JPG'
import sky2 from '../assets/Garden/sky2.JPG'
import sky3 from '../assets/Garden/sky3.JPG'
import sky4 from '../assets/Garden/sky4.JPG'
import sky5 from '../assets/Garden/sky5.JPG'

import vac1 from '../assets/Garden/vac1.jpg'
import vac2 from '../assets/Garden/vac2.jpg'
import vac3 from '../assets/Garden/vac3.jpg'
import vac4 from '../assets/Garden/vac4.jpg'
import vac5 from '../assets/Garden/vac5.jpg'

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function GalleryContGard() {
  const [modgal, setmodgal] = useState(false);
  const [indexgal, setindexgal] = useState(0);
  const [indexmod, setindexmod] = useState(0);
  const toggleModalgal = ()=> {
    setmodgal(!modgal);
    setindexmod(0);
  }
  const handleincrease = ()=> {
    setindexmod(indexmod+1);
  }
  const handleincreasemax = ()=> {
    setindexmod(indexmod);
  }
  const handledecrease = ()=> {
    if (indexmod === 0) return;
    setindexmod(indexmod-1);
  }
  const handlechange = (e, index) => {
    toggleModalgal();
    e.persist();
    setindexgal(index);
  }
  if (modgal) {
    document.body.classList.add('active-modalgal');
  }
  else {
    document.body.classList.remove('active-modalgal');
  }
    return (
      <div className="divgardencontainer">
        <Headercomp/>
        <div className='masonrycontainer'>
          <ImageList
            variant="quilted"
            cols={4}
            gap={1}
            rowHeight={300}>
            {itemData.map((item, index) => (
              <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} className="contforoverlay" >
                <img
                  {...srcset(item.img, 300, item.rows, item.cols)}
                  alt={item.title}
                  loading="lazy"
                  className='imgfrommas'
                  onClick={(e) => handlechange(e, index)}
                  />
                 <ImageListItemBar position="bottom" title={item.title} />
              </ImageListItem>
            ))}
          </ImageList>
            </div>
            {modgal &&(
              <div className='modalgal'>
                <div className='overlaymod' onClick={toggleModalgal}></div>
                <div className='modal-contentgal'>
                  <img
                    src={itemData[indexgal].list[indexmod]}
                    className='image-modalgal'
                  />
                  <button
                    className='close-modalgal'
                    onClick={toggleModalgal}>
                      <p className='xofthebuttongal'>X</p>
                  </button>
                  {itemData[indexgal].length -1 > indexmod ?
                    <button
                    className='indexmodinc'
                    onClick={handleincrease}>
                        <p className='xofthebuttongal'>{">"}</p>
                    </button>
                  :
                  <button
                    className='indexmodinc'
                    onClick={handleincreasemax}>
                        <p className='xofthebuttongal'>{">"}</p>
                    </button>
                  }
                  <button
                    className='indexmoddec'
                    onClick={handledecrease}>
                      <p className='xofthebuttongal'>{"<"}</p>
                  </button>
                  <p className='indexmodnumb'>{indexmod+1}/{itemData[indexgal].length}</p>
                </div>
              </div>
              )}
      </div>
    );
  };
  const itemData = [
    {
      img: ain1,
      id:0,
      title: 'Ainazi',
      rows: 2,
      cols: 2,
      list: [ain1, ain2, ain3, ain4, ain5],
      length: 5,
    }, 
    {
      img: arai1,
      id:0,
      title: 'Araisu',
      cols: 2,
      list: [arai1, arai2, arai3, arai4, arai5],
      length: 5,
    }, 
    {
      img: balt1,
      id:0,
      title: 'Baltezers',
      list: [balt1, balt2, balt3, balt4, balt5],
      length: 5,
    }, 
    {
      img: berg1,
      id:0,
      title: 'Bergi',
      rows:2,
      list: [berg1, berg2, berg3, berg4, berg5],
      length: 5,
    }, 
    {
      img: bied1,
      id:0,
      title: 'Biedrinja',
      rows: 2,
      list: [bied1, bied2, bied3, bied4, bied5],
      length: 5,
    }, 
    {
      img: brod1,
      id:0,
      title: 'Brod',
      cols: 2,
      list: [brod1, brod2, brod3, brod4, brod5],
      length: 5,
    }, 
    {
      img: iz1,
      id:0,
      title: 'Izlase',
      rows: 2,
      cols: 2,
      list: [iz1, iz2, iz3, iz4, iz5],
      length: 5,
    }, 
    {
      img: lil1,
      id:0,
      title: 'Lilaste',
      rows:2,
      list: [lil1, lil2, lil3, lil4, lil5],
      length: 5,
    }, 
    {
      img: sel1,
      id:0,
      title: 'Selju',
      list: [sel1, sel2, sel3, sel4, sel5],
      length: 5,
    }, 
    {
      img: sky1,
      id:0,
      title: 'Sky garden',
      cols: 2,
      list: [sky1, sky2, sky3, sky4, sky5],
      length: 5,
    }, 
    {
      img: vac1,
      id:0,
      title: 'Vaciesa',
      cols: 2,
      list: [vac1, vac2, vac3, vac4, vac5],
      length: 5,
    }, 
  ];
 
 