import './galleryCont.css'
import { useEffect, useState } from 'react';
import Headercomp from "../component/headerComp";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import mas1 from '../assets/Int/masonry1.jpg'
import mas2 from '../assets/Int/masonry2.jpg'
import mas3 from '../assets/Int/masonry3.jpg'

import cesud1 from '../assets/Int/cesud1.jpg'
import cesud2 from '../assets/Int/cesud2.jpg'

import pbalt1 from '../assets/Int/pbalt1.JPG'
import pbalt2 from '../assets/Int/pbalt2.JPG'
import pbalt3 from '../assets/Int/pbalt3.JPG'
import pbalt4 from '../assets/Int/pbalt4.JPG'
import pbalt5 from '../assets/Int/pbalt5.JPG'

import red1 from '../assets/Int/red1.jpg'
import red2 from '../assets/Int/red2.jpg'
import red3 from '../assets/Int/red3.jpg'
import red4 from '../assets/Int/red4.JPG'
import red5 from '../assets/Int/red5.JPG'


import edin1 from '../assets/Int/edin1.jpg'
import edin2 from '../assets/Int/edin2.jpg'
import edin3 from '../assets/Int/edin3.jpg'
import edin4 from '../assets/Int/edin4.jpg'
import edin5 from '../assets/Int/edin5.jpg'
import edin6 from '../assets/Int/edin6.jpg'

import laim1 from '../assets/Int/laim1.jpg'
import laim2 from '../assets/Int/laim2.jpg'
import laim3 from '../assets/Int/laim3.jpg'
import laim4 from '../assets/Int/laim4.jpg'
import laim5 from '../assets/Int/laim5.jpg'
import laim6 from '../assets/Int/laim6.jpg'
import laim7 from '../assets/Int/laim7.jpg'

import konf1 from '../assets/Int/konf1.jpg'
import konf2 from '../assets/Int/konf2.jpg'
import konf3 from '../assets/Int/konf3.JPG'
import konf4 from '../assets/Int/konf4.JPG'
import konf5 from '../assets/Int/konf5.JPG'
import konf6 from '../assets/Int/konf6.JPG'

import pmeza1 from '../assets/Int/pmeza1.JPG'
import pmeza2 from '../assets/Int/pmeza2.JPG'
import pmeza3 from '../assets/Int/pmeza3.jpg'
import pmeza4 from '../assets/Int/pmeza4.jpg'

import pmar1 from '../assets/Int/pmar1.jpg'
import pmar2 from '../assets/Int/pmar2.jpg'
import pmar3 from '../assets/Int/pmar3.jpg'
import pmar4 from '../assets/Int/pmar4.jpg'
import pmar5 from '../assets/Int/pmar5.jpg'
import pmar6 from '../assets/Int/pmar6.jpg'
import pmar7 from '../assets/Int/pmar7.jpg'
import pmar8 from '../assets/Int/pmar8.jpg'
import pmar9 from '../assets/Int/pmar9.jpg'
import pmar10 from '../assets/Int/pmar10.jpg'
import pmar11 from '../assets/Int/pmar11.jpeg'
import pmar12 from '../assets/Int/pmar12.jpeg'

import sal1 from '../assets/Int/sal1.JPG'
import sal2 from '../assets/Int/sal2.JPG'
import sal3 from '../assets/Int/sal3.JPG'
import sal4 from '../assets/Int/sal4.JPG'
import sal5 from '../assets/Int/sal5.JPG'
import sal6 from '../assets/Int/sal6.JPG'
import sal7 from '../assets/Int/sal7.JPG'
import sal8 from '../assets/Int/sal8.jpg'
import sal9 from '../assets/Int/sal9.JPG'

import iek1 from '../assets/Int/Ieskaties1.jpg'
import iek2 from '../assets/Int/Ieskaties2.jpg'
import iek3 from '../assets/Int/Ieskaties3.jpg'
import iek4 from '../assets/Int/Ieskaties4.jpg'
import iek5 from '../assets/Int/Ieskaties5.jpg'
import iek6 from '../assets/Int/Ieskaties6.jpg'
import iek7 from '../assets/Int/Ieskaties7.jpg'

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function GalleryCont() {
  const [modgal, setmodgal] = useState(false);
  const [indexgal, setindexgal] = useState(0);
  const [indexmod, setindexmod] = useState(0);
  const toggleModalgal = ()=> {
    setmodgal(!modgal);
    setindexmod(0);
  }
  const handleincrease = ()=> {
    setindexmod(indexmod+1);
  }
  const handleincreasemax = ()=> {
    setindexmod(indexmod);
  }
  const handledecrease = ()=> {
    if (indexmod === 0) return;
    setindexmod(indexmod-1);
  }
  const handlechange = (e, index) => {
    toggleModalgal();
    e.persist();
    setindexgal(index);
  }
  if (modgal) {
    document.body.classList.add('active-modalgal');
  }
  else {
    document.body.classList.remove('active-modalgal');
  }
    return (
      <div className="divgardencontainer">
        <Headercomp/>
        <div className='masonrycontainer'>
          <ImageList
            variant="quilted"
            cols={4}
            gap={1}
            rowHeight={300}>
            {itemData.map((item, index) => (
              <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} className="contforoverlay" >
                <img
                  {...srcset(item.img, 300, item.rows, item.cols)}
                  alt={item.title}
                  loading="lazy"
                  className='imgfrommas'
                  onClick={(e) => handlechange(e, index)}
                  />
                 <ImageListItemBar position="bottom" title={item.title} />
              </ImageListItem>
            ))}
          </ImageList>
            </div>
            {modgal &&(
              <div className='modalgal'>
                <div className='overlaymod' onClick={toggleModalgal}></div>
                <div className='modal-contentgal'>
                  <img
                    src={itemData[indexgal].list[indexmod]}
                    className='image-modalgal'
                  />
                  <button
                    className='close-modalgal'
                    onClick={toggleModalgal}>
                      <p className='xofthebuttongal'>X</p>
                  </button>
                  {itemData[indexgal].length -1 > indexmod ?
                    <button
                    className='indexmodinc'
                    onClick={handleincrease}>
                        <p className='xofthebuttongal'>{">"}</p>
                    </button>
                  :
                  <button
                    className='indexmodinc'
                    onClick={handleincreasemax}>
                        <p className='xofthebuttongal'>{">"}</p>
                    </button>
                  }
                  <button
                    className='indexmoddec'
                    onClick={handledecrease}>
                      <p className='xofthebuttongal'>{"<"}</p>
                  </button>
                  <p className='indexmodnumb'>{indexmod+1}/{itemData[indexgal].length}</p>
                </div>
              </div>
              )}
      </div>
    );
  };
  const itemData = [
    {
      img: iek1,
      id:0,
      title: 'Salons Ieskaties',
      rows: 2,
      cols: 2,
      list: [iek1, iek2, iek3, iek4, iek5, iek6, iek7],
      length: 7,
    },
    {
      img: mas2,
      id:1,
      title: 'Dzivoklis Riga',
      list: [mas1, mas2, mas3],
      length: 3,
    },
    {
      img: pbalt3,
      id:2,
      title: 'Privātmāja Baltezerā',
      list: [pbalt1, pbalt2, pbalt3, pbalt4, pbalt5],
      length: 5,
    },
    {
      img: pmar11,
      id:3,
      title: 'Privātmāja Mārupē',
      rows: 2,
      cols: 2,
      list: [pmar1, pmar2, pmar3, pmar4, pmar5, pmar6, pmar7, pmar8, pmar9, pmar10, pmar11, pmar12],
      length: 12,
    },
    {
      img: pmeza3,
      id:4,
      title: 'Privātmāja Mežaparkā',
      list: [pmeza1, pmeza2, pmeza3, pmeza4],
      length: 4,
    },
    {
      img: edin6,
      id:5,
      title: 'Apartamenti Edinburga',
      list: [edin1, edin2, edin3, edin4, edin5, edin6],
      length: 6,
    },
    {
      img: cesud1,
      id:5,
      title: 'Cēsu  Dziltsarakstu Nodaļa',
      list: [cesud1, cesud2],
      length: 2,
    },
    {
      img: konf6,
      id:6,
      cols:2,
      title: 'Konferenĉu  Zāle',
      list: [konf1, konf2, konf3, konf4, konf5, konf6],
      length: 6,
    },
    {
      img: red1,
      id:7,
      title: 'Redzes Ekselences Centrs Barons',
      list: [red1, red2, red3, red4, red5],
      length: 5,
    },
    {
      img: laim2,
      id:8,
      cols:2,
      rows:2,
      title: 'Laimas muzejs',
      list: [laim1, laim2, laim3, laim4, laim5, laim6, laim7],
      length: 7,
    },
    {
      img: sal1,
      id:9,
      cols:2, 
      rows:2,
      title: 'Sabiedriskie Salaspils Sporta Centrs',
      list: [sal1, sal2, sal3, sal4, sal5, sal6, sal7, sal8, sal9],
      length: 9
    },
  ];
 
 