import React, {useEffect} from "react";
import { Trans, useTranslation} from 'react-i18next';
import AOS from 'aos';
import "./aosProfileGarden.css"
import 'aos/dist/aos.css';
import prof from '../../assets/Garden/prof.jpg'
import worker from "../../assets/Garden/worker.JPG"



export default function AosProfileGarden() {
    useEffect(() => {
        AOS.init();
      }, [])
  
    return (
        <div className="profilecontainergar">
          <div className="divverte"></div>
          <p className="titledivgar" 
              data-aos="zoom-out"
              data-aos-offset="300"
              data-aos-once="true"
              data-aos-duration="1000"><Trans i18nKey="description.titleprofilegar"></Trans></p>
          <div className="profiledivimggar">
            <div className="profiledivleftgar">
              <img
                src={worker}
                className="profileimgleftgar" 
                data-aos="zoom-out"
                data-aos-offset="300"
                data-aos-once="true"
                data-aos-duration="2000">
              </img>
              <p className="titleimgleftgar"
               data-aos="zoom-out"
               data-aos-offset="100"
               data-aos-once="true"
               data-aos-duration="2000">[<Trans i18nKey="description.titletextprofile"></Trans>]</p>
              <p className="textimgleftgar"
                  data-aos="zoom-out"
                  data-aos-offset="100"
                  data-aos-once="true"
                  data-aos-duration="2000">
              <Trans i18nKey="description.aboutmetextgarden"></Trans></p>
            </div>
            <div className="profiledivrightgar">
              <img
                src={prof}
                className="profileimggar"
                data-aos="zoom-out"
                data-aos-offset="300"
                data-aos-once="true"
                data-aos-duration="2000">
              </img>
              <p className="titleimgrightgar"
                  data-aos="zoom-out"
                  data-aos-offset="100"
                  data-aos-once="true"
                  data-aos-duration="2000">[<Trans i18nKey="description.titletextprofile"></Trans>]</p>
              <p className="textimgrightgar"
                  data-aos="zoom-out"
                  data-aos-offset="100"
                  data-aos-once="true"
                  data-aos-duration="2000">
              <Trans i18nKey="description.aboutmetextgarden"></Trans></p>
            </div>
          </div>
      </div>
        );
}