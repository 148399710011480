import React from "react";
import { Trans, useTranslation} from 'react-i18next';
import './btoGalleryInt.css'
import { Link } from "react-router-dom";


export default function BtogalleryInt() {
    return (
        <Link style = {{textDecoration: "none"}} to="../gallery">
            <div className="btogcontainer">
                <div className="textbtogcontainer">
                    <p className="buttontogtext"><Trans i18nKey="description.gotogallery"></Trans></p>
                </div>
            </div>
        </Link>
        );
}