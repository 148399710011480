import React from "react";
import { Trans, useTranslation} from 'react-i18next';
import './headerComp.css'
import { Link } from "react-router-dom";
import logo from '../assets/logo.png';
import "../assets/Alegreya-VariableFont_wght.ttf";
import i18next from "i18next";


export default function Headercomp() {
    const {t, i18n} = useTranslation()
    
    const changeLanguage = (language) => {
      i18n.changeLanguage(language)
      console.log(i18n.language);
    }       
    return (
            <div className="headerdiv">
                <Link className="logoContainer" to="/">
                    <img
                        src={logo}
                        alt="logo"
                        className="logo"
                        >
                    </img>
                </Link>
                <div className="divheaderlink">
                    <Link style={{textDecoration:"none"}} className="titleG" to="/interior">
                        <Trans i18nKey="description.titleimgInt"/>
                    </Link>
                    <Link style={{textDecoration:"none"}} className="titleG" to="/garden">
                        <Trans i18nKey="description.titleimgGarden"/>
                    </Link>
                    <Link style={{textDecoration:"none"}} className="titleG" to="/contact">
                        <Trans i18nKey="description.headercont"/>
                    </Link>
                </div>
                <div className="divlangagepicker">
                    <p>{i18n.language.toUpperCase()}</p>
                    <p className="pluslpicker">+</p>
                    {i18n.language === "lv" &&
                        <div className="selectlangagediv">
                            <p className="firsttomove" onClick={() => changeLanguage("en")}>EN</p>
                            <p className="secondtomove" onClick={() => changeLanguage("fr")}>FR</p>
                        </div>
                    }
                    {i18n.language === "en" &&
                        <div  className="selectlangagediv">
                            <p className="firsttomove" onClick={() => changeLanguage("lv")}>LV</p>
                            <p className="secondtomove" onClick={() => changeLanguage("fr")}>FR</p>
                        </div>
                    }
                    {i18n.language === "fr" &&
                        <div className="selectlangagediv">
                            <p className="firsttomove" onClick={() => changeLanguage("lv")}>LV</p>
                            <p className="secondtomove" onClick={() => changeLanguage("en")}>EN</p>
                        </div>
                    }
                </div>
            </div>
        );
}
                    // onClick={() => changeLanguage("lv")}